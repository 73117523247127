<template>
  <div>
    <div class="container my-lg-4 my-md-3 my-sm-2">
      <h1>ERROR {{ CODE}} : {{MSG}}</h1>
      <p>{{ DESC }}</p>
      <a :href="IMG" target="_blank">
        <img :src="IMG" :alt="'Rocket Downrange - Error ' + CODE + ' : ' +  MSG"/>
      </a>
      <p class="my-4 text-center">Image generated by <a href="https://www.craiyon.com/" target="_blank" rel="noopener" class="craiyon">Craiyon.com (formerly DALL-E MINI, under Personal Use in TOS)</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  props:{
    CODE: String,
    MSG: String,
    DESC: String,
    IMG: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  img{
    max-width: 100%;
    display: block;
    margin: auto;
  }
  .craiyon{
    text-decoration: underline;
  }
</style>
