<template>
  <div class="locations container">
      <Info
        header="Launch & Landing Locations"
        description= "Our pale blue dot is home to several spaceport launch and landing pads.  This page is dedicated to showcasing some of the finest rocket launch sites from around the world, keep your eyes to the sky for a rocket downrange! "
      />
      <LaunchLocations 
        x="45.965" 
        y="63.305" 
        z="11" 
        content="Baikonur Cosmodrome"
        operator="Roscosmos"
        region="Kazakh Steppe, Kazakhstan"
        launch_pads="71 (most inactive or destroyed)"
        first_launch="4th of October, 1957"
        description="Baikonur Cosmodrome, located in the desert steppes of Kazakhstan, is the world's oldest and largest space launch facility and has been the launch site for numerous historic space missions, including the first human spaceflight by Yuri Gagarin. With its unparalleled history and infrastructure, Baikonur Cosmodrome remains a vital component of the global space industry and a symbol of humanity's quest for exploration and discovery."
      />
      <LaunchLocations 
        x="31.423333" 
        y="-104.758889" 
        z="12.5" 
        content="Corn Ranch"
        operator="Blue Origin"
        region="Van Horn, Texas, United States of America"
        launch_pads="1 active"
        first_launch="13th of November, 2006"
        description="Corn Ranch, located in West Texas, is a private space launch site owned by Blue Origin, a spaceflight company founded by Jeff Bezos. The site serves as a testing ground for Blue Origin's New Shepard rocket and is a key component of the company's vision to make space travel accessible to all."
      />
      <LaunchLocations 
        x="40.96996401645288" 
        y="100.27049714366552" 
        z="11" 
        content="Jiuquan Satellite Launch Center"
        operator="China"
        region="	Ejin, Alxa, Inner Mongolia"
        launch_pads="2 active"
        first_launch="19th of November, 1999"
        description="Jiuquan Satellite Launch Center is a Chinese space launch facility located in the Gobi Desert that plays a crucial role in China's space program, including the launches of the country's first manned spacecraft and its lunar exploration missions. With its strategic location and advanced launch infrastructure, Jiuquan Satellite Launch Center is a key player in the global space industry and a symbol of China's growing capabilities in space."
      />
      <LaunchLocations 
        x="28.524167" 
        y="-80.650833" 
        z="11" 
        content="Kennedy Space Center"
        operator="NASA & US Space Force"
        region="Cape Canaveral, Florida, United States of America"
        launch_pads="2 active, 1 leased, 1 inactive"
        first_launch="9th of November, 1967"
        description="Kennedy Space Center, located on the east coast of Florida, is NASA's primary launch facility and a hub for human spaceflight, scientific research, and space exploration. With its rich history, state-of-the-art facilities, and proximity to Cape Canaveral Air Force Station, Kennedy Space Center is a world-renowned center for space innovation and discovery."
      />
      <LaunchLocations 
        x="-39.2615" 
        y="177.864876" 
        z="12.5" 
        content="Launch Complex 1"
        operator="Rocket Lab"
        region="Ahuriri Point, New Zealand"
        launch_pads="2 active"
        first_launch="25th of May, 2017"
        description="Rocket Lab's Launch Complex 1, located on the Mahia Peninsula in New Zealand, is a premier facility for small satellite launches that offers rapid and cost-effective access to space. With its advanced launch vehicle technology and streamlined launch processes, Rocket Lab's Launch Complex 1 is a leading player in the commercial space industry."
      />
      <LaunchLocations 
        x="57.435833" 
        y="-152.337778" 
        z="12.5" 
        content="Pacific Spaceport Complex"
        operator="Astra"
        region="Kodiak Island, Alaska, United States of America"
        launch_pads="2 active"
        first_launch="6th of November, 1998"
        description="Astra's Pacific Spaceport Complex, located in Kodiak, Alaska, is a space launch site that provides cost-effective and flexible access to space for small satellite launches. Astra's unique launch technology and strategic location make Pacific Spaceport Complex an attractive option for commercial, government, and academic customers looking to enter the space industry."
      />
      <LaunchLocations 
        x="13.719998336927675" 
        y="80.23032453147856" 
        z="11" 
        content="Satish Dhawan Space Centre"
        operator="Indian Space Research Organization"
        region="Sriharikota, Tirupati district, Andhra Pradesh, India"
        launch_pads="3 active"
        first_launch="9th of August, 1979"
        description="Satish Dhawan Space Centre, located on the east coast of India, is a premier space launch facility that plays a vital role in India's space program, including the launches of the country's lunar and Mars exploration missions. With its state-of-the-art launch infrastructure, experienced workforce, and strategic location, Satish Dhawan Space Centre is a key player in the global space industry and a symbol of India's growing capabilities in space."
      />
      <LaunchLocations 
        x="32.9901031839758" 
        y="-106.97655048138984" 
        z="11" 
        content="Spaceport America"
        operator="Virgin Galactic"
        region="Sierra County, New Mexico, United States of America"
        launch_pads="1 active"
        first_launch="25th of September, 2006"
        description="Spaceport America, located in the desert of New Mexico, is a state-of-the-art launch facility that offers unique opportunities for commercial and government spaceflights, space tourism, and research in microgravity. With its advanced infrastructure and visionary leadership, Spaceport America is a hub for innovation and entrepreneurship in the space industry."
      />
      <LaunchLocations 
        x="25.985752215917778" 
        y="-97.18223317068392" 
        z="11" 
        content="Starbase"
        operator="SpaceX"
        region="Boca Chica, Texas, United States of America"
        launch_pads="3 active"
        first_launch="25th of July, 2019"
        description="SpaceX Starbase, located in Boca Chica, Texas, is a cutting-edge rocket production and launch facility that plays a key role in SpaceX's ambitious plans to colonize Mars and make human life multi-planetary. With its innovative engineering and pioneering spirit, SpaceX Starbase is at the forefront of the commercial space race."
      />
      <LaunchLocations 
        x="37.93751126741556" 
        y="-75.47042545896504" 
        z="11" 
        content="Wallops Island & Flight Facility"
        operator="NASA / US Space Force"
        region="Wallops Island, Virginia, United States of America"
        launch_pads="3 active"
        first_launch="4th of July, 1945"
        description="Wallops Island and Flight Facility, located on the eastern shore of Virginia, is a NASA-run launch site that supports a wide range of scientific, commercial, and military missions, including the testing of new rocket systems and payloads. With its unique coastal location, cutting-edge technology, and experienced workforce, Wallops Island and Flight Facility is a vital hub for space innovation and exploration."
      />
      <LaunchLocations 
        x="34.732778" 
        y="-120.568056" 
        z="12.5" 
        content="Vandenberg Space Force Base"
        operator="NASA / US Space Force"
        region="Santa Barbara County, California, United States of America"
        launch_pads="7 active, 4 inactive"
        first_launch="16th of December, 1958"
        description="Vandenberg Space Force Base is a military installation located in California, USA that serves as a space launch and test facility for government and commercial satellites. With its prime location on the West Coast and state-of-the-art facilities, Vandenberg Space Force Base is a crucial component of America's space capabilities."
      />
      <LaunchLocations 
        x="28.24356133392111" 
        y="102.03394444682098" 
        z="11" 
        content="Xichang Satellite Launch Center"
        operator="China"
        region="Xichang, Liangshan, Sichuan"
        launch_pads="2 active"
        first_launch="29th of January, 1984"
        description="Jiuquan Satellite Launch Center is a Chinese space launch facility located in the Gobi Desert that plays a crucial role in China's space program, including the launches of the country's first manned spacecraft and its lunar exploration missions. With its strategic location and advanced launch infrastructure, Jiuquan Satellite Launch Center is a key player in the global space industry and a symbol of China's growing capabilities in space."
      />
      <LaunchLocations 
        x="30.401513379405348" 
        y="130.97688422868234" 
        z="11" 
        content="Yoshinobu Launch Complex"
        operator="Japan"
        region="Tanegashima, Japan"
        launch_pads="3 active"
        first_launch="9th of September, 1975"
        description="Japan's main space launch site operated by JAXA, includes several launch complexes and hosting a wide variety of space missions, from scientific and commercial satellites to interplanetary probes and the International Space Station resupply missions. It offers a unique location for space launches, with its proximity to the equator allowing for increased payload capacity and cost-effectiveness."
      />
    <about />
  </div>
</template>


<script>
// @ is an alias to /src
import about from "@/components/About-template.vue";
import Error404 from "@/components/Error.vue";
import LaunchLocations from "@/components/LaunchLocations.vue";
import Info from "@/components/Info-Template.vue";

export default {
  name: "Locations",
  components: {
    about,
    Error404,
    LaunchLocations,
    Info
  },
  metaInfo: {
    title: "Rocket Downrange | Locations",
    meta: [
      { name: "author", content: "Github: @TheKicker" },
      {
        name: "description",
        content:
          "Our pale blue dot is home to several spaceport launch and landing pads.  This page is dedicated to showcasing some of the finest rocket launch sites from around the world, keep your eyes to the sky for a rocket downrange!",
      },
      {
        name: "keywords",
        content:
          "Rocket, Downrange, Baikonur Cosmodrome, Kennedy Space Center, Corn Ranch, Launch Complex 1, Starbase, United States of America, Space Force, USA, launch pads, landing pads, rocket launch, locations",
      },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "Rocket Downrange | Locations" },
      { property: "og:site_name", content: "Rocket Downrange" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      {
        property: "og:url",
        content: "https://www.rocketdownrange.com/locations",
      },
      {
        property: "og:image",
        content: "https://www.rocketdownrange.com/rocketdownrange.jpg",
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "Our pale blue dot is home to several spaceport launch and landing pads.  This page is dedicated to showcasing some of the finest rocket launch sites from around the world, keep your eyes to the sky for a rocket downrange! ",
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://www.rocketdownrange.com/locations",
      },
      {
        name: "twitter:title",
        content: "Rocket Downrange | Locations",
      },
      {
        name: "twitter:description",
        content:
          "Our pale blue dot is home to several spaceport launch and landing pads.  This page is dedicated to showcasing some of the finest rocket launch sites from around the world, keep your eyes to the sky for a rocket downrange! ",
      },
      // Your twitter handle, if you have one.
      { name: "twitter:creator", content: "Github: @TheKicker" },
      {
        name: "twitter:image:src",
        content: "https://www.rocketdownrange.com/rocketdownrange.jpg",
      },

      // Google / Schema.org markup:
      { itemprop: "name", content: "Rocket Downrange | Locations" },
      {
        itemprop: "description",
        content:
          "Our pale blue dot is home to several spaceport launch and landing pads.  This page is dedicated to showcasing some of the finest rocket launch sites from around the world, keep your eyes to the sky for a rocket downrange! ",
      },
      {
        itemprop: "image",
        content: "https://www.rocketdownrange.com/rocketdownrange.jpg",
      },
    ],
  },
};
</script>
